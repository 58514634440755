import type {GetServerSideProps, NextPage} from 'next'
import {PageHeader} from '@components/page-header';
import {PageFooter} from '@components/page-footer';
import Link from 'next/link';
import Image from 'next/image';
import algoliasearch from 'algoliasearch';
import React from 'react';
import {API, LatestNewsItem, LatestReviewItem} from 'lib/server/api';
import {StrapiEntity, StrapiList} from 'lib/strapi';
import {ReviewWall} from '@components/review-wall';
import {AppSearch} from '@components/app-search';
import Head from 'next/head';
import {getSession} from 'next-auth/react';

interface HomePageProps {
    user?: { name: string }
    latestReviews: StrapiList<LatestReviewItem>,
    latestNews: StrapiList<LatestNewsItem>
}


const appId = 'NMFM7NEFWF';
const apiKey = 'c401656e57f2d8091010c2a3cd6cb027';
const searchClient = algoliasearch(appId, apiKey);

interface LatestNewsSectionProps {
    articles: StrapiEntity<LatestNewsItem>[];
}

const LatestNewsSection = (props: LatestNewsSectionProps) => {
    return (

        <div className="grid max-w-screen-2xl mx-auto px-4 py-12 grid-cols-1 md:grid-cols-2 gap-default">
            {props.articles.slice(0, 2).map(n => (
                <Link key={n.attributes.slug} href={`/greinar/${n.attributes.slug}`} passHref={true}>
                    <div className="text-primary-500 font-bold text-center cursor-pointer group">
                        <div className="bg-white rounded-md relative overflow-hidden"
                             style={{transform: 'translateZ(0)'}}>
                            <div className="w-full aspect-h-9 aspect-w-16">
                                <Image
                                    priority
                                    src={n.attributes.image?.data?.attributes?.url}
                                    objectFit={'cover'}
                                    layout="fill"
                                    alt={n.attributes.image?.data?.attributes?.alternativeText}/>
                            </div>
                            {/*<div*/}
                            {/*    className="flex text-xs items-center leading-3 justify-end px-4 bottom-0 text-white bg-primary-500 absolute left-0 right-0 h-8 translate-y-8 group-hover:translate-y-0 transition-transform ease-out-expo duration-500">*/}
                            {/*    <div*/}
                            {/*        className="opacity-0 translate-y-1 group-hover:opacity-100 group-hover:translate-y-0 transition-all delay-100 duration-300 items-center flex">*/}
                            {/*        Les*/}
                            {/*        Meira<ChevronDoubleRightIcon className="w-4 h-4 ml-2"/></div>*/}
                            {/*</div>*/}
                        </div>
                        <h3 className="text-lg mt-2 lg:mt-4 lg:text-xl">{n.attributes.title}</h3>
                    </div>
                </Link>
            ))}
        </div>
    )
}

const Home: NextPage<HomePageProps> = (props) => {
    return (
        <div>
            <Head>
                <title>Reyta</title>
            </Head>
            <PageHeader/>
            <header className="bg-primary-500 text-white md:py-48 py-24 font-bold">
                <div className="max-w-screen-2xl mx-auto px-4 grid grid-cols-3 lg:grid-cols-holder gap-default">
                    <div className="col-span-3 lg:col-span-2">
                        <h2 className="text-4xl md:text-6xl leading-tight">
                            <div className="text-accent-500">Endiliga.</div>
                            <div>
                                Ein pallur at ummæla føroyskar fyritøkur
                            </div>
                        </h2>
                        <div className="mt-8">
                            <AppSearch standalone/>
                        </div>
                    </div>
                </div>
            </header>
            <section>
                <div className="max-w-screen-2xl mx-auto px-4">
                    <h2 className="text-lg lg:text-xl text-primary-500 pt-12 pb-7 text-center font-bold">
                        Seinastu ummæli
                    </h2>
                </div>
                <ReviewWall reviews={props.latestReviews}/>
            </section>
            <section className="bg-mint pb-8">
                <h2 className="hidden">
                    Seinastu Greinar
                </h2>
                <LatestNewsSection articles={props.latestNews}/>
            </section>
            <PageFooter/>
        </div>
    )
}

// noinspection JSUnusedGlobalSymbols
export default Home

// noinspection JSUnusedGlobalSymbols
export const getServerSideProps: GetServerSideProps<HomePageProps> = async (context) => {
    const session = await getSession(context)
    const [latestNews, latestReviews] = await Promise.all([API.getLatestNews(), API.getLatestReviews()])
    return {props: {latestNews, latestReviews, session}}
}

