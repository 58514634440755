import {Star} from '@components/star';
import classNames from 'classnames';
import {useMemo} from 'react';

interface RatingProps {
    rating: number;
    pending?: boolean;
}

const PlainStar = (props: { color: string }) => (
    <svg width="26" height="26" viewBox="0 0 39 37" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M19.0809 29.2675L30.8729 36.4167L27.7436 22.9425L38.1617 13.8767L24.4426 12.7075L19.0809 0L13.7191 12.7075L0 13.8767L10.4182 22.9425L7.28889 36.4167L19.0809 29.2675Z"
            fill={props.color}/>
    </svg>
)

function getColor(rating: number): string {
    if (rating > 5) {
        return '#61CD5F'
    } else if (rating > 4) {
        return '#B7DC4C'
    } else if (rating > 3) {
        return '#FAC130'
    } else if (rating > 2) {
        return '#EE7531'
    } else {
        return '#EE3737'
    }
}

export const Rating = (props: RatingProps) => {
    const {rating} = props
    const color = useMemo(() => getColor(rating), [rating]);
    return (
        <div className={classNames('flex gap-0.5', props.pending ? 'animate-pulse' : '')}>
            <PlainStar color={rating >= 1 ? color : '#E4E4E4'}/>
            <PlainStar color={rating >= 2 ? color : '#E4E4E4'}/>
            <PlainStar color={rating >= 3 ? color : '#E4E4E4'}/>
            <PlainStar color={rating >= 4 ? color : '#E4E4E4'}/>
            <PlainStar color={rating >= 5 ? color : '#E4E4E4'}/>
        </div>
    )
}
